import request from "@/base/util/request.js";
// 
// 删除
export const deleteResidentpersonInfoById = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolResidentpersonInfo/deleteResidentpersonInfoById',
        method: 'get',
        params
    })
}

// GET 
//分页查询 
export const queryResidentpersonInfoByPage = (data) => {
        return request({
            url: 'epidemicControl/epidemiccontrolResidentpersonInfo/queryResidentpersonInfoByPage',
            method: 'post',
            data
        })
    }
    //数据导入
export const importResidentpersonInfosPath = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolResidentpersonInfo/importResidentpersonInfosPath',
        method: 'get',
        params
    })
}

/**
 * 导入身份证号码文件提取身份证号码格式为逗号隔开的字符串
 * @param {*} params 
 * @returns 
 */
export const fileToIdNumbers = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolResidentpersonInfo/fileToIdNumbers',
        method: 'get',
        params
    })
}

/**
 * 市级推送原始表.xlsx--》提取表.xlsx
 * @param {*} params 
 * @returns 
 */
export const originalTableToExtractionTable = (params) => {
    return request({
        url: 'epidemicControl/epidemiccontrolResidentpersonInfo/originalTableToExtractionTable',
        method: 'get',
        params,
        responseType: "blob",

    })
}