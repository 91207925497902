<template>
  <div class="content">
    <div class="search">
      <el-form ref="form" :model="form" label-width="200px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="">
              <!-- <el-input
                v-model.trim="listQuery.idNumbers"
                placeholder="请输入身份证号码，多个请用英文逗号分隔"
                clearable
              ></el-input> -->
              <el-input
                :placeholder="
                  selectOptionData[listQuery.selectType].placeholderText
                "
                clearable
                v-model.trim="listQuery.selectKeyWords"
                class="input-with-select"
              >
                <el-select
                  v-model="listQuery.selectType"
                  slot="prepend"
                  placeholder="请选择"
                >
                  <el-option
                    :label="item.lable"
                    :value="item.value"
                    v-for="(item, index) in selectOptionData"
                    :key="index"
                  ></el-option>
                  <!-- <el-option label="手机号" value="2"></el-option>
                  <el-option label="姓名" value="3"></el-option> -->
                </el-select>
                <el-button
                  slot="append"
                  type="primary"
                  icon="el-icon-search"
                  @click="onSearchHandel"
                  >数据检索</el-button
                >
                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
              </el-input>
            </el-form-item></el-col
          >
          <!-- <el-col :span="3">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="onSearchHandel"
              >立即搜索</el-button
            >
          </el-col> -->
          <el-col :span="2" :offset="1">
            <el-upload
              action=""
              :before-upload="beforeUpload"
              :limit="1"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :show-file-list="false"
              ref="batchImportUploadBtn"
              :disabled="batchImportUploadIng"
              :http-request="fileuploadHandle"
            >
              <el-popover
                placement="top-start"
                title="下载模板"
                width="200"
                trigger="hover"
              >
                <div>
                  点击
                  <el-link
                    type="primary"
                    class="muban_download"
                    download="身份证号批量检索模板.xlsx"
                    href="/epidemicControl/身份证号批量检索模板.xlsx"
                    >这里下载</el-link
                  >身份证号批量检索模板，然后点击上方按钮上传模板批量搜索
                </div>
                <el-button
                  type="success"
                  icon="el-icon-upload"
                  :disabled="batchImportUploadIng"
                  :loading="batchImportUploadIng"
                  slot="reference"
                  >批量搜索</el-button
                >
              </el-popover>
            </el-upload>
          </el-col>

          <el-col :span="2" :offset="1">
            <el-upload
              action=""
              :before-upload="beforeUpload"
              :limit="1"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :show-file-list="false"
              ref="batchImportUploadBtn"
              :disabled="batchImportUploadIng"
              :http-request="dataGetfileuploadHandle"
            >
              <el-popover
                placement="top-start"
                title="下载模板"
                width="200"
                trigger="hover"
              >
                <div>
                  点击
                  <el-link
                    type="primary"
                    class="muban_download"
                    download="市级推送原始表.xlsx"
                    href="/epidemicControl/市级推送原始表.xlsx"
                    >这里下载</el-link
                  >市级推送原始表模板，然后点击上方按钮上传模板导出提取数据
                </div>
                <el-button
                  type="success"
                  icon="el-icon-download"
                  :disabled="batchImportUploadIng"
                  :loading="batchImportUploadIng"
                  slot="reference"
                  >数据提取</el-button
                >
              </el-popover>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div :style="{ 'min-height': tableHeight + 'px' }">
      <el-skeleton :rows="6" animated v-if="listDataLoading" />
      <el-collapse
        v-model="activeNames"
        @change="collapseHandleChange"
        :accordion="true"
        v-if="!listDataLoading && tableData.length > 0"
      >
        <el-collapse-item
          :title="'身份证号：' + item.idNumber + '    联系电话：' + item.contact+ '    姓名：' + item.name"
          :name="index"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <el-descriptions title="户籍信息" border>
            <el-descriptions-item label="家庭户号/集体户号">{{
              item.household
            }}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{
              item.name
            }}</el-descriptions-item>
            <el-descriptions-item label="与户主关系">{{
              item.relationshipWithHouseholder
            }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{
              item.gender
            }}</el-descriptions-item>
            <el-descriptions-item label="年龄">{{
              item.age
            }}</el-descriptions-item>
            <el-descriptions-item label="国籍">{{
              item.nationality
            }}</el-descriptions-item>
            <el-descriptions-item label="民族">{{
              item.national
            }}</el-descriptions-item>
            <el-descriptions-item label="证件类型">{{
              item.idType
            }}</el-descriptions-item>
            <el-descriptions-item label="证件号码">{{
              item.idNumber
            }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{
              item.contact
            }}</el-descriptions-item>
            <el-descriptions-item label="人口类型">{{
              item.populationType
            }}</el-descriptions-item>
            <el-descriptions-item label="居住状态">{{
              item.livingState
            }}</el-descriptions-item>
            <el-descriptions-item label="18类重点人群">{{
              item.keyGroups
            }}</el-descriptions-item>
            <el-descriptions-item label="五大症状人群">{{
              item.symptomGroups
            }}</el-descriptions-item>
            <el-descriptions-item label="填报市">{{
              item.fillCity
            }}</el-descriptions-item>
            <el-descriptions-item label="填报市">{{
              item.fillCity
            }}</el-descriptions-item>
            <el-descriptions-item label="填报县">{{
              item.fillCounty
            }}</el-descriptions-item>
            <el-descriptions-item label="填报乡">{{
              item.fillTownship
            }}</el-descriptions-item>
            <el-descriptions-item label="住房性质">{{
              item.housingNature
            }}</el-descriptions-item>
            <el-descriptions-item label="居委会">{{
              item.neighborhoodCommittee
            }}</el-descriptions-item>
            <el-descriptions-item label="街（路）">{{
              item.street
            }}</el-descriptions-item>
            <el-descriptions-item label="小区（单位、酒店）">{{
              item.community
            }}</el-descriptions-item>
            <el-descriptions-item label="楼号、单元及房间号">{{
              item.buildingNumber
            }}</el-descriptions-item>
            <el-descriptions-item label="行政村">{{
              item.administrativeVillages
            }}</el-descriptions-item>
            <el-descriptions-item label="自然村">{{
              item.naturalVillage
            }}</el-descriptions-item>

            <!-- <el-descriptions-item label="备注">
              <el-tag size="small">学校</el-tag>
            </el-descriptions-item> -->
          </el-descriptions>

          <h3>核酸信息</h3>
          <hesuanTable
            :table-data="item.hesuanDataList"
            v-if="item.hesuanDataList"
          ></hesuanTable>
          <h3>疫苗信息</h3>
          <vaccine-inoculation-table
            :table-data="item.yimiaoDataList"
            v-if="item.yimiaoDataList"
          ></vaccine-inoculation-table>
          <h3>返乡计划</h3>
          <epidemiccontrol-plantoreturn-info-table
            :table-data="item.fanxiangDataList"
            v-if="item.fanxiangDataList"
          ></epidemiccontrol-plantoreturn-info-table>
        </el-collapse-item>
      </el-collapse>
      <el-empty description="暂无数据" v-if="tableData.length == 0"></el-empty>
    </div>
    <div class="page-box mt20r">
      <el-pagination
        v-if="tableData.length != 0"
        background
        @current-change="getTableList"
        :current-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-backtop target=".use-background .content"></el-backtop>
  </div>
</template>

<script>
import {
  queryResidentpersonInfoByPage,
  fileToIdNumbers,
  originalTableToExtractionTable,
} from "../../../api/epidemiccontrolResidentpersonInfo/index";
import { queryHesuanInfoByPage } from "../../../api/epidemiccontrolHesuanInfo/index";
import { queryVaccineInoculationInfoByPage } from "../../../api/epidemiccontrolVaccineInoculationInfo/index";

import { queryPlantoreturnInfoByPage } from "../../../api/epidemiccontrolPlantoreturnInfo/index";

import { fileupload } from "../../../api/uploadfile/index";
import hesuanTable from "../hesuanInfo/components/hesuanTable.vue";
import vaccineInoculationTable from "../vaccineInoculationInfo/components/vaccineInoculationTable.vue";

import epidemiccontrolPlantoreturnInfoTable from "../epidemiccontrolPlantoreturnInfo/components/epidemiccontrolPlantoreturnInfoTable.vue";

export default {
  components: {
    hesuanTable,
    vaccineInoculationTable,
    epidemiccontrolPlantoreturnInfoTable,
  },
  data() {
    return {
      selectOptionData: [
        {
          placeholderText: "请输入身份证号",
          lable: "身份证号",
          value: 0,
        },
        {
          placeholderText: "请输入搜索手机号",
          lable: "手机号",
          value: 1,
        },
        {
          placeholderText: "请输入搜索姓名",
          lable: "姓名",
          value: 2,
        },
      ],
      batchImportUploadIng: false, //正在处理批量上传
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        selectType: 0, //搜索类型
        selectKeyWords: "", //搜索关键词
        idNumbers: "", //多个证件号
        idNumber: "", //单个身份证作为查询条件
        name: "", //姓名作为查询条件
        contact: "", //联系方式
      },
      total: 0,
      tableData: [],
      //  hesuanTableData: [], //核酸

      listDataLoading: false, //列表数据是否正在加载
      total: 0,
      activeNames: "",
      form: {
        name: "",
      },
    };
  },
  methods: {
    beforeUpload(file) {
      this.batchImportUploadIng = true;
    },
    //批量搜索点击
    fileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            //告诉后台，文件已经上传成功以及文件信息，准备批量处理
            let paramers = {
              path: data.nginxFileReadPath,
            };
            fileToIdNumbers(paramers)
              .then((res) => {
                this.batchImportUploadIng = false;

                if (res.code == 1) {
                  this.listQuery.idNumbers = res.data;
                  //重新查询数据
                  this.getTableData();
                } else if (res.code == 1001) {
                  this.$message.error("数据操作错误");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                this.batchImportUploadIng = false;
                // this.$message.error(e);
              });
          } else if (res.code == -1) {
            this.batchImportUploadIng = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.batchImportUploadIng = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },
    //数据提取
    dataGetfileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            //告诉后台，文件已经上传成功以及文件信息，准备批量处理
            let paramers = {
              path: data.nginxFileReadPath,
            };
            originalTableToExtractionTable(paramers)
              .then((res) => {
                this.batchImportUploadIng = false;

                // if (res.code == 1) {
                //   this.listQuery.idNumbers = res.data;
                //   //重新查询数据
                //   this.getTableData();
                // } else if (res.code == 1001) {
                //   this.$message.error("数据操作错误");
                // } else {
                //   this.$message.error(res.msg);
                // }
                const blob = new Blob([res.data]);
                var downloadElement = document.createElement("a");
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                // 文件名一般是在res.headers里：content-disposition;fileName=xxxxxxxxxx.csv，这个让后端统一规定文件名怎么放前端就怎么取就行
                let str =
                  typeof res.headers["content-disposition"] === "undefined"
                    ? res.headers["Content-Disposition"].split(";")[1]
                    : res.headers["content-disposition"].split(";")[1];

                let filename =
                  typeof str.split("fileName=")[1] === "undefined"
                    ? str.split("filename=")[1]
                    : str.split("fileName=")[1];
                downloadElement.download = decodeURIComponent(filename); //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
              })
              .catch((e) => {
                this.batchImportUploadIng = false;
              });
          } else if (res.code == -1) {
            this.batchImportUploadIng = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.batchImportUploadIng = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },

    //获取搜索数据
    getTableData() {
      let data = JSON.parse(JSON.stringify(this.listQuery));
      if (data.idNumbers) {
        data.idNumbers = data.idNumbers.split(",");
      }else{
        data.idNumbers=[];
      }

      queryResidentpersonInfoByPage(data).then((res) => {
        if (res.code == 1) {
          this.tableData = [];
          this.tableData = res.data.list;
          this.total = res.data.total;
          //把展开的面板内部 的数据马上请求一下
          this.collapseHandleChange(this.activeNames);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //点击搜索按钮事件函数
    onSearchHandel() {

      let selectType = this.listQuery.selectType;
      let selectKeyWords = this.listQuery.selectKeyWords;
      this.listQuery.idNumber ="";
      this.listQuery.contact ="";
      this.listQuery.name ="";
      this.listQuery.idNumbers ="";
      if (selectType == 0) {
        this.listQuery.idNumber = selectKeyWords;
      } else if (selectType == 1) {
        this.listQuery.contact = selectKeyWords;
      } else if (selectType == 2) {
        this.listQuery.name = selectKeyWords;
      }
      this.getTableData();
    },
    collapseHandleChange(index) {
      if (index !== "") {
        let item = this.tableData[index];
        let thishesuanDataList = item.hesuanDataList;
        if (!thishesuanDataList) {
          //这里准备请求核酸
          let listQuery = {
            pageNum: 1,
            pageSize: 10000,
            idNumber: item.idNumber, //证件号
          };
          queryHesuanInfoByPage(listQuery)
            .then((res) => {
              if (res.code == 1) {
                let data = res.data.list;

                this.$set(item, "hesuanDataList", data);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }

        let thisyimiaoDataList = item.yimiaoDataList;
        if (!thisyimiaoDataList) {
          //这里准备请求疫苗
          let listQuery = {
            pageNum: 1,
            pageSize: 10000,
            idNumber: item.idNumber, //证件号
          };
          queryVaccineInoculationInfoByPage(listQuery)
            .then((res) => {
              if (res.code == 1) {
                let data = res.data.list;

                this.$set(item, "yimiaoDataList", data);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }

        let thisfanxiangDataList = item.fanxiangDataList;
        if (!thisfanxiangDataList) {
          //这里准备请求返乡
          let listQuery = {
            pageNum: 1,
            pageSize: 10000,
            idNumber: item.idNumber, //证件号
          };
          queryPlantoreturnInfoByPage(listQuery)
            .then((res) => {
              if (res.code == 1) {
                let data = res.data.list;

                this.$set(item, "fanxiangDataList", data);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }
      }
    },

    getTableList() {},
  },
};
</script>

<style>
.el-select .el-input {
  width: 110px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.page-box {
  text-align: right;
}
.ml10r {
  margin-left: 10px;
}
.mt20r {
  margin-top: 20px;
}
</style>